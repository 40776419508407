@import "flex_mixin";
$mainColor: var(--deforms-main-color);
$mainColorAssociate: var(--deforms-main-color-associate);
$accentColor: var(--deforms-accent-color);
$inputBg: var(--deforms-input-bg);
$labelColor: var(--deforms-label-color);
$descColor: var(--deforms-desc-color);
$inputColor: var(--deforms-input-color);
$overlayBg: var(--deforms-overlay-bg);
$mainFont: var(--deforms-main-font);
$calendarImg: var(--deforms-calendar-img);

$tabletPortrait: 768;
$tabletLandscape: 992;
$largeScreen: 1200;

$DesktopLarge: "(min-width: #{$largeScreen+px})"; //Large screen col-lg
$DesktopLarger: "(min-width: #{($largeScreen)+200+px})"; //Larger screen
$Mobile: "(max-width: #{($tabletLandscape)-1+px})"; //Mobile + Tablet
$Phone: "(max-width: #{($tabletPortrait)-1+px})"; //Mobile col-xs
$ExcludePhone: "(min-width: #{$tabletPortrait+px})"; //PORTRAIT + DESKTOP
$Portrait: "(min-width: #{$tabletPortrait+px}) and (max-width: #{($tabletLandscape)-1+px})"; //Tablet PORTRAIT col-sm
$Desktop: "(min-width: #{$tabletLandscape+px}) and (max-width: #{($largeScreen)-1+px})"; //DESKTOP col-md
$AllDesktop: "(min-width: #{($tabletLandscape)+px})"; //only desktop
$DesktopLargeSmallH: "(min-width: #{$largeScreen+px}) and (max-height: #{($tabletPortrait)-1+px})";
$DesktopLargeBigH: "(min-width: #{$largeScreen+px}) and (min-height: 900px)";

@mixin media_DesktopLarge {
  @media (min-width: #{$largeScreen+px}) {
    @content;
  }
}

@mixin media_DesktopLarge_800 {
  @media (min-width: #{$largeScreen+px}) and (min-height: 800px) {
    @content;
  }
}

@mixin media_DesktopLarger {
  @media (min-width: #{$largeScreen+200+px}) {
    @content;
  }
}

@mixin media_DesktopLarger_800 {
  @media (min-width: #{$largeScreen+200+px}) and (min-height: 800px) {
    @content;
  }
}

@mixin media_DesktopXXL {
  @media (min-width: #{$largeScreen+400+px}) and (min-height: 900px) {
    @content;
  }
}

@mixin media_Desktop_Mobile {
  @media (max-width: #{($largeScreen)-1+px}) {
    @content;
  }
}

@mixin media_Mobile {
  @media (max-width: #{($tabletLandscape)-1+px}) {
    @content;
  }
}

@mixin media_600 {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin media_Phone {
  @media (max-width: #{($tabletPortrait)-1+px}) {
    @content;
  }
}

@mixin media_Portrait {
  @media (min-width: #{$tabletPortrait+px}) and (max-width: #{($tabletLandscape)-1+px}) {
    @content;
  }
}

@mixin media_Desktop {
  @media (min-width: #{$tabletLandscape+px}) and (max-width: #{($largeScreen)-1+px}) {
    @content;
  }
}

@mixin media_AllDesktop {
  @media (min-width: #{($tabletLandscape)+px}) {
    @content;
  }
}

@mixin media_DesktopLargeSmallH {
  @media (min-width: #{$largeScreen+px}) and (max-height: #{($tabletPortrait)-1+px}) {
    @content;
  }
}

@mixin media_DesktopLargeBigH {
  @media (min-width: #{$largeScreen+px}) and (min-height: 700px) {
    @content;
  }
}


@mixin media_minHeight_900 {
  @media (min-height: #{900px}) {
    @content;
  }
}


@mixin media_ExcludePhone {
  @media (min-width: #{$tabletPortrait+px}) {
    @content;
  }
}

@mixin transition($val: all 0.3s ease) {
  -webkit-transition: $val;
  -moz-transition: $val;
  -ms-transition: $val;
  -o-transition: $val;
  transition: $val;
}

@mixin multiple_transition($val...) {
  -webkit-transition: $val;
  -moz-transition: $val;
  -ms-transition: $val;
  -o-transition: $val;
  transition: $val;
}

@mixin filter($val...) {
  -webkit-filter: $val;
  filter: $val;
}

@mixin transform($val) {
  -webkit-transform: $val;
  -moz-transform: $val;
  -ms-transform: $val;
  -o-transform: $val;
  transform: $val;
}

@mixin transformOrigin($val) {
  -webkit-transform-origin: $val;
  -moz-transform-origin: $val;
  -ms-transform-origin: $val;
  -o-transform-origin: $val;
  transform-origin: $val;
}

@mixin boxSizing($val: border-box) {
  -webkit-box-sizing: $val;
  -moz-box-sizing: $val;
  box-sizing: $val;
}

@mixin boxShadow($val) {
  -webkit-box-shadow: $val;
  -moz-box-shadow: $val;
  box-shadow: $val;
}

@mixin boxShadowMulti($val...) {
  -webkit-box-shadow: $val;
  -moz-box-shadow: $val;
  box-shadow: $val;
}

@mixin borderRadius($val) {
  -webkit-border-radius: $val;
  -moz-border-radius: $val;
  border-radius: $val;
}

@mixin opacity($val) {
  opacity: $val;
  filter: Alpha(opacity=$val*100)
}

@mixin radius($val) {
  -webkit-border-radius: $val;
  -moz-border-radius: $val;
  border-radius: $val;
}

@mixin gradientVert($start, $stop, $startPosition: 0%, $stopPosition: 100%) {
  background: $start;
  background: -moz-linear-gradient(top, $start $startPosition, $stop $stopPosition);
  background: -webkit-linear-gradient(top, $start $startPosition, $stop $stopPosition);
  background: linear-gradient(to bottom, $start $startPosition, $stop $stopPosition);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start', endColorstr='$stop', GradientType=0);
}

@mixin gradientHorz($start, $stop, $startPosition: 0%, $stopPosition: 100%) {
  background: $stop;
  background: -moz-linear-gradient(left, $start $startPosition, $stop $stopPosition);
  background: -webkit-linear-gradient(left, $start $startPosition, $stop $stopPosition);
  background: linear-gradient(to right, $start $startPosition, $stop $stopPosition);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start', endColorstr='$stop', GradientType=1);
}

@mixin gradientDiagonal($start, $stop, $startPosition: 0%, $stopPosition: 100%) {
  background: $start;
  background: -moz-linear-gradient(45deg, $start $startPosition, $stop $stopPosition);
  background: -webkit-linear-gradient(45deg, $start $startPosition, $stop $stopPosition);
  background: linear-gradient(45deg, $start $startPosition, $stop $stopPosition);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start', endColorstr='$stop', GradientType=1);

}

@mixin input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @include radius(0);
  border: none;
}

@mixin inputPlaceholder($color : #333) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-ms-input-placeholder {
    color: $color;
  }
}

@mixin select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  @include radius(0);
  &::-ms-expand {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
  }
}

@mixin preserve3D {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

@mixin textOnSquareElem($size) {
  text-align: center;
  width: $size;
  height: $size;
  line-height: $size;
}

@mixin clearfix {
  zoom: 1;
  &:after {
    clear: both;
  }
  &:after,
  &:before {
    content: "";
    display: table;
  }
}


@mixin btn($color:false) {
  display: inline-block;
  height: 50px;
  overflow: hidden;
  text-align: center;
  a {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    padding: 0 1.5em;
    height: 100%;
    font: {
      family: $mainFont;
      size: 16px;
      weight: bold;
      style: normal;
    }
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 1;
    overflow: hidden;
    position: relative;
    line-height: 120%;
    border: {
      width: 1px;
      style: solid;
    }
    min-width: 160px;
    @include transition();
    @if ($color) {
      color: $color;
      border-color: $color;
    } @else {
      color: $mainColor;
      border-color: $mainColor;
    }
    &.alternative {
      color: $mainColorAssociate;
      border-color: $mainColorAssociate;
      &:hover {
        background: $mainColorAssociate;
        color: $mainColor;
      }
    }
    * {
      color: inherit;
      font-weight: inherit;
    }
    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
      background: $mainColor;
      color: $mainColorAssociate;
    }
  }
}


@mixin genericBtn() {
  @include btn();
  a {
    border: {
      width: 0 5px;
    }
    border-top-color: transparent;
    border-bottom-color: transparent;
    background-color: $mainColor;
    color: $mainColorAssociate;
    border-right-color: $accentColor;
    border-left-color: $accentColor;
    &:hover {
      border-width: 1px 5px;
      color: $mainColor;
      background: transparent;
      border-color: $mainColor;
    }
  }
}
