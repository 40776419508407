// DISCLAIMER:
// This is just a quick, very untested boilerplate to get some decent CSS structure based on the default fields.
// If it works well, you can just change the few css variables below, otherwise just remove the theme support for the
// default CSS. You can also override the rules eventually
@import "variables_mixins";

:root {
  --deforms-main-color: #000;
  --deforms-main-color-associate: #FFF;
  --deforms-accent-color: #CCC;
  --deforms-input-bg: #FFF;
  --deforms-label-color: #000;
  --deforms-desc-color: #333;
  --deforms-input-color: #333;
  --deforms-overlay-bg: #FFF;
  --deforms-main-font: Arial, Helvetica, sans-serif;
  --deforms-calendar-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6mm' height='6.35mm' viewBox='0 0 17 18'%3E%3Cpath d='M16,2.5H13.2V2.1a.6.6,0,1,0-1.2,0v.5H5V2.1a.65.65,0,0,0-.6-.6.65.65,0,0,0-.6.6v.5H1a.58.58,0,0,0-.6.6V16c0,.3.2.5.6.5H16a.58.58,0,0,0,.6-.6V3.1A.58.58,0,0,0,16,2.5Zm-.6,12.8H1.5v-9H15.4Zm.1-10.1H1.5V3.7H3.9V4a.65.65,0,0,0,.6.6A.56.56,0,0,0,5,4V3.7h7V4a.6.6,0,1,0,1.2,0V3.7h2.3Z'/%3E%3Cpath d='M4.4,9.4h7.5a.62.62,0,0,0,.5-.8.66.66,0,0,0-.5-.4H4.4a.53.53,0,0,0-.5.7C4,9.2,4.2,9.4,4.4,9.4Z'/%3E%3Cpath d='M3.9,12H8.5a.6.6,0,1,0,0-1.2H3.9a.6.6,0,0,0,0,1.2Z'/%3E%3C/svg%3E");
}

.deform {
  margin-top: 10px;
  max-width: 840px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  position: relative;

  // Loading overlay
  &.disabled {
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: -20px;
      left: -20px;
      width: calc(100% + 40px);
      height: calc(100% + 40px);
      z-index: 10000;
      background: rgba(0, 0, 0, 0.3);
    }

    &:before {
      content: '';
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      opacity: 0.8;
      background-color: $accentColor;
      animation: forms_spin 1s infinite linear;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 10001;
      margin-left: -30px;
      margin-top: -30px;
    }

  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  p {
    margin-bottom: 20px;
  }

  .form_wrap {
    @include flexbox();
    @include flex-wrap(wrap);
    margin-left: -10px;
    margin-right: -10px;
    width: auto;
  }

  .fields {
    @include flexbox();
    @include flex-wrap(wrap);
    margin-left: -10px;
    margin-right: -10px;

    .field {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .field {
    width: 50%;
    padding: 0 10px;
    margin: 10px 0;
    @include media_Phone {
      width: 100%;
    }
    @for $i from 1 through 12 {
      &.col_#{$i} {
        width: calc(100% / 12 * #{$i});
        @include media_Phone() {
          width: 100%;
        }
      }
    }

    &.field_message {
      width: 100%;
    }

    &:not(.fieldtype_checkbox):not(.field_capcha):not(.fieldtype_submit) {
      .input_wrap,
      .select_wrap {
        position: relative;
        background: $inputBg;
        margin-top: 10px;
      }
    }

    &.fieldtype_recaptcha_v3 {
      width: 100%;
      margin: 0;

      .error_holder {
        top: 0;
      }
    }

    &.fieldtype_checkbox {
      @include flexbox();
      @include align-items(center);
      @include media_Phone() {
        @include align-items(flex-start);
      }

      .input_wrap {
        position: relative;
        margin-right: 10px;
      }

      .error_holder {
        white-space: nowrap;
      }
    }

    &.fieldtype_submit {
      text-align: right;

      input[type=submit] {
        padding: 0 1.5em;
        height: 48px;
        font: {
          family: $mainFont;
          size: 16px;
          weight: bold;
          style: normal;
        }
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        z-index: 1;
        overflow: hidden;
        position: relative;
        line-height: 120%;
        border: {
          width: 1px 5px;
          style: solid;
          color: $accentColor;
        }
        min-width: 160px;
        @include transition();
        background: $mainColor;
        color: $mainColorAssociate;

        &:hover {
          cursor: pointer;
          background: transparent;
          border-color: $mainColor;
          color: $mainColor;
        }
      }

      input[type=submit]:disabled {
        @include transition(none);
        opacity: .8;

        &:hover {
          cursor: not-allowed;
          background: $mainColor;
          color: $mainColorAssociate;
          border: {
            width: 1px 5px;
            style: solid;
            color: $accentColor;
          }
        }
      }
    }

    &.fieldtype_radio {
      input[type=radio] {
        padding: 0;
        height: auto;
        width: auto;
        display: inline-block;
      }
      .item_label{
        display: inline-block;
        margin: 10px;
        .label {
          display: inline-block;
        }
      }
    }

    &.group {
      padding-bottom: 30px;
      margin-top: 20px;

      .field {
        @include flexbox();
        @include align-items(center);
        margin-top: 20px;

        label {
          @include order(2);
        }

        .input_wrap {
          @include order(1);
          margin-top: 0 !important;
          line-height: 1;
          margin-right: 10px;
        }
      }

      .field_hotel_languages,
      .field_brand_languages {
        @include justify-content(flex-end);

        label {
          @include order(1);
          margin-right: 20px;
        }

        .select_wrap {
          @include order(2);
          margin-top: 0 !important;
        }
      }
    }

    &.status_error {
      input, textarea, select {
        outline: 2px solid rgba(255, 0, 0, 0.8);
      }
    }

    label {
      display: block;
      line-height: 160%;

      .label {
        color: $labelColor;
        font-weight: bold;
        display: block;
        font-size: 16px;

        .optional {
          font-size: small;
        }
      }

      .desc {
        color: $descColor;
        display: block;
        font-size: 14px;
      }
    }

    input[type=text],
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      @include radius(0);
      color: $inputColor;
      background: transparent;
      height: 50px;
      line-height: 50px;
      padding: 0 15px;
      width: 100%;
      position: relative;
      z-index: 200;
      min-width: 100px;
      font-family: $mainFont;
    }

    select option {
      background: $inputBg;
    }

    textarea {
      margin-top: 10px;
      width: 100%;
      padding: 2em;
      min-height: 120px;
      font-family: $mainFont;
    }

    .textarea_wrap {
      position: relative;

    }

    .input_wrap.datepicker:after {
      content: '';
      width: 20px;
      height: 20px;
      background: $calendarImg no-repeat center center transparent;
      background-size: 100% auto;
      position: absolute;
      top: 50%;
      right: 1em;
      color: $inputColor;
      z-index: 100;
      margin-top: -10px;
    }

    .select_wrap:after {
      content: '\25be';
      position: absolute;
      line-height: 50px;
      right: 1em;
      top: 0;
      color: $inputColor;
      z-index: 100;
    }

    .error_holder {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      max-height: 30px;
      font-size: 12px;
      line-height: 15px;
      padding: 3px;
      box-sizing: border-box;
      color: red;
    }
  }

  .form_overlay {
    display: none;
    position: absolute;
    top: -20px;
    left: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    z-index: 10000;
    background: rgba(0, 0, 0, 0.5);

    &.visible {
      display: block;
    }

    .form_overlay_content {
      position: absolute;
      top: 20px;
      left: 20px;
      width: calc(100% - 40px);
      height: auto;
      background: $overlayBg;
      box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
      border: 1px solid $accentColor;
      padding: 40px;
      z-index: 2;
    }

    .form_overlay_close.close {
      position: absolute;
      right: 5px;
      top: 5px;
    }

    .the_content {
      text-align: center;
    }

    .clickable_underlay {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    }

    .btns {
      text-align: center;
    }

    .btn {
      margin-top: 20px;
      @include genericBtn();
    }

    .message {
      display: none;

      &.active {
        display: block;
      }
    }

    .error_list {
      color: #CC0000;
    }
  }
}